import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Dialog,
    TextField,
    Typography,
    styled
} from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';

interface SurveyFormProps {
    handleSurveyNotificationClose: () => void;
    isSurveyNotificationOpen: boolean;
    handleSurveySubmission: (data: any) => void;
    role: number;
    handleTextFieldForTeacherChange: (value: string, name: string) => void;
    handleTextFieldForAdminChange: (value: string, name: string) => void;
    isFormSubmited: boolean;
    successMessage: string;
    ErrorMessage: string;
    formIsEmpty: boolean;
    adminFormData: any;
    formData: any
    name: any
    surveyForm: any,
}

interface InputHOCProps {
    handleChange: (value: string, name: string) => void;
    name: string;
    question: string;
    errorMessage: string;
    value: string;
}
const InputHOC: React.FC<InputHOCProps> = ({
    handleChange,
    name,
    question,
    errorMessage,
    value,
}) => {
    
    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(event.target.value, name); 
      };
    return (
        <>
            <Typography style={webStyle.textfield}>{question}</Typography>
            <TextField
                fullWidth
                variant="outlined"
                name={name}
                value={value}
                onChange={onInputChange}
            />
            {errorMessage && (
                <Typography style={webStyle.errorText}>{errorMessage}</Typography>
            )}
        </>
    );
};

export default function SurveyForm({
    isSurveyNotificationOpen,
    handleSurveySubmission,
    handleSurveyNotificationClose,
    role,
    handleTextFieldForTeacherChange,
    handleTextFieldForAdminChange,
    adminFormData,
    formData,
    isFormSubmited,
    successMessage,
    name,
    surveyForm
}: SurveyFormProps) {
    const [formSubmit,setFormSubmit]=useState(false)
     
    const handleSubmit = () => {
        setFormSubmit(true);
        let error = false;
        console.log(formData)
        Object.entries(formData).forEach(([key, value]) => {
          if (!value) {
            error = true; 
          }
        });
        if (error) {
          return;
        }
        handleSurveySubmission(role === 1 ? adminFormData : formData);
      };
    return (
        <>
            <CustomDialog open={isSurveyNotificationOpen}>
                <Box style={webStyle.dialogHeader}>
                    <Typography style={webStyle.headerHeading}>Job Completion Survey - {name}</Typography>
                </Box>
                <Box style={webStyle.subBox}>
                    <Typography style={{ ...webStyle.kindlyFill, marginBottom: "15px" }}>{
                        role==1?<>We greatly value your feedback! Please take a moment to share your thoughts on the completion of your recent Substitute Teacher</>:<>We greatly value your feedback! Please take a moment to share your thoughts on the completion of your recent teaching job.</>}</Typography>
                    {surveyForm.map((questions: any) => (
                        <>
                            <InputHOC
                                handleChange={handleTextFieldForTeacherChange}
                                name={questions.attributes.question}
                                question={questions.attributes.question}
                                errorMessage={
                                    formSubmit && !formData[questions.attributes.question]
                                      ? "This field is required"
                                      : ""
                                  }
                    
                                value={formData?.[questions.attributes.question] || ""}
                            />
                        </>
                    ))}

                </Box>
                <Box style={webStyle.buttonbox}>
                    <Button style={webStyle.submitBtn} onClick={handleSubmit} data-test-id="submitTestId">
                        Submit
                    </Button>
                </Box>
            </CustomDialog>

            <CustomDialog open={isFormSubmited}>
                <Box style={webStyle.dialogHeader2}>
                    <Typography style={webStyle.headerHeading}>Survey Submitted.</Typography>
                    <ClearIcon onClick={handleSurveyNotificationClose} />
                </Box>
                <Box style={webStyle.subBox2}>
                    <Typography style={webStyle.kindlyFill}>{successMessage}</Typography>
                </Box>
                <Box style={webStyle.buttonbox2}>
                    <Button style={webStyle.cancelBtn} onClick={handleSurveyNotificationClose}>
                        Cancel
                    </Button>
                </Box>
            </CustomDialog>
        </>
    );
}



const CustomDialog = styled(Dialog)({
    borderRadius: '16px',
    '& .MuiDialog-paperWidthSm': {
        minWidth: '650px',
        borderRadius: '16px',
        "@media only screen and (max-width: 816px)": {
            minWidth: '500px'
        },
        "@media only screen and (max-width: 553px)": {
            minWidth: '300px'
        },
    }
})


const webStyle = {
    option: {
        display: "flex",
        gap: "40px",
        alignItems: "center"
    },
    textstyle: {
        color: "#1E293B",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "22px",
        padding: "8px 6px",
        cursor: "pointer"
    },
    loginBtn: {
        color: "#395169",
        border: "1px solid #395169",
        borderRadius: "8px",
        padding: "10px 32px",
        fontFamily: "Inter",
        textTransform: "none" as "none",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
    },
    signBtn: {
        color: "#FFFFFF",
        border: "1px solid #395169",
        padding: "10px 32px",
        borderRadius: "8px",
        background: "#395169",
        fontFamily: "Inter",
        fontSize: "14px",
        textTransform: "none" as "none",
        fontWeight: 700,
        lineHeight: "22px",
    },
    profile: {
        display: "flex",
        gap: 12,
        paddingLeft: "40px",
        borderLeft: "1px solid #E2E8F0"
    },
    name: {
        color: "#1E293B",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "22px",
    },
    menuList: {
        color: "#1E293B",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
    },
    dialogHeader: {
        padding: '24px 16px 24px 40px',
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #E2E8F0'
    },
    dialogHeader2: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #E2E8F0',
        padding: '24px 16px 24px 40px',
        maxHeight: "80px"
    },
    headerHeading: {
        color: '#0F172A',
        fontFamily: 'Inter',
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '32px',
    },
    subBox: {
        padding: '32px 40px',
        borderBottom: '1px solid #E2E8F0'
    },
    subBox2: {
        padding: '37px 40px 16px 40px',
        maxHeight: "78px",
        borderBottom: '1px solid #E2E8F0',
    },
    kindlyFill: {
        color: '#334155',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
    },
    textfield: {
        color: '#64748B',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '22px',
        marginBottom: '6px'
    },
    buttonbox: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '24px 40px'
    },
    buttonbox2: {
        justifyContent: 'space-between',
        display: 'flex',
        maxHeight: "104px",
        padding: '24px 40px',
    },
    cancelBtn: {
        color: '#395169',
        fontFamily: 'Inter',
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: '24px',
        width: '100%',
        background: '#fff',
        textTransform: 'none' as 'none',
        height: '56px',
        cursor: 'pointer',
    },
    submitBtn: {
        color: '#fff',
        background: '#395169',
        width: '100%',
        fontFamily: 'Inter',
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: '24px',
        cursor: 'pointer',
        textTransform: 'none' as 'none',
        height: '56px'
    },
    deleteBox: {
        padding: '60px 40px 32px 40px',
        position: 'relative' as 'relative'
    },
    deleteClose: {
        position: 'absolute' as 'absolute',
        top: 20,
        right: 20,
        cursor: 'pointer'
    },
    errorText: {
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 400,
        color: '#DC2626',
        marginTop: '4px',
    }
}

export { InputHOC }