import React from "react";
// Customizable Area Start
import { Box,Typography, Grid, styled, CircularProgress } from "@material-ui/core";
import Navbar from "../../../components/src/Navbar.web";
import Footer from "../../../components/src/Footer.web"
import { logo } from "../../postcreation/src/assets";
import { noData } from "../../postcreation/src/assets";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderScreen(){
    if (this.state.loader) {
      return <>
      <Box style={{width:"100%" , height :"600px" ,display:"flex" , justifyContent :"center" , alignItems:"center"}}>
        <CircularProgress />
      </Box>
      </>
    }else if(!this.state.loader && this.state.title.length == 0 && this.state.description.length == 0){
      return  <>
          <Box style={{ width: "100%",flexDirection:"column", height: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={noData} width={"100%"} style={{maxWidth:"400px"}} alt="" />
          <Typography style={{ ...webStyle.name, textAlign:"center" ,fontSize: '16px',fontWeight:400,marginTop:20}}>
                  No About Us found
                </Typography>
          </Box>
      </>       
    }else{
      return <>
       <TextContent item sm={6} xs={12}>
                <Typography style={webStyle.name}>
                  {this.state.title}
                </Typography>
                <Typography style={{ ...webStyle.name, fontSize: '16px',fontWeight:400,marginTop:20,textAlign: 'justify' }}>
                  {this.state.description}
                </Typography>
              </TextContent>
              <Grid item sm={6} xs={12} style={{display:'flex', justifyContent:'center'}}>
                <img src={noData}/>
              </Grid>
      </>
   
    }
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <>
        <CustomNavbar>
          <Navbar navigation={this.props.navigation} id={""} NaviagationPage={"About"} />
        </CustomNavbar>
        <CustomProfileHead>
                    <ArrowBackIos data-test-id='btnBack' onClick={this.navigateToHome} style={webStyle.backIcon} />
                    <NavbarTitle>
                        <img src={logo} />
                    </NavbarTitle>
                </CustomProfileHead>
        <MainBox>
          <div>
            <Navigation>
              <Typography data-test-id="navigateToHome" onClick={this.navigateToHome} style={webStyle.home}>Home</Typography>
              <ArrowForwardIos style={webStyle.forwardArrow} />
              <Typography style={webStyle.joblisting}>About Us</Typography>
            </Navigation>
            <ProfileHeading>
              <PostHeading>About Us</PostHeading>
            </ProfileHeading>
            <InnerBox container>
            {this.renderScreen()}
            </InnerBox>
          </div>
        </MainBox>
        <Footer navigation={this.props.navigation} id={""} NaviagationPage={""} />
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const TextContent=styled(Grid)({
  paddingLeft:'65px',
  "@media only screen and (max-width: 599px)": {
    paddingLeft:'unset'
  },
})
const PostHeading = styled(Typography)({
  color: "#1E293B",
  fontFamily: "Inter",
  fontSize: "30px",
  fontWeight: 700,
  lineHeight: "40px",
  "@media only screen and (max-width: 414px)": {
    fontSize: '16px'
  },
})
const CustomNavbar = styled(Box)({
  "@media only screen and (max-width: 657px)": {
    display: 'none'
  },
})
const CustomProfileHead = styled(Box)({
  display: 'none',
  "@media only screen and (max-width: 657px)": {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#395169',
    padding: 20,
    position: 'relative',
    paadingBottom: 37
  }
})
const MainBox = styled(Box)({
  background: "#F8F8F8",
  position: "relative",
  padding: "140px 208px 120px 208px",
  "@media only screen and (max-width: 1330px)": {
    padding: 100,
  },
  "@media only screen and (max-width: 940px)": {
    paddingLeft:'20px',
    paddingRight:'20px'
  },
  "@media only screen and (max-width: 688px)": {
    paddingLeft: 29,
    paddingRight: 29
  },
  "@media only screen and (max-width: 571px)": {
    padding: '29px'
  },
  "@media only screen and (max-width: 404px)": {
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: '29px',
    paddingTop: '29px'
  },
  "@media only screen and (max-width: 375px)": {
    paddingLeft: 4,
    paddingRight: 4,
    paddingBottom: '29px',
    paddingTop: '29px'
  }
})
const InnerBox = styled(Grid)({
  background: '#fff',
  borderRadius: '16px',
  marginTop: '20px',
  padding: '30px 15px',
  display: 'flex',
})
const Navigation = styled(Box)({
  display: "flex",
  gap: "10px",
  position: "absolute" as "absolute",
  top: 32,
  left: 114,
  "@media only screen and (max-width: 657px)": {
    left: 38
  },
  "@media only screen and (max-width: 571px)": {
    display: 'none',
  }
})
const ProfileHeading = styled(Box)({
  marginBottom: "32px",
  display: "flex",
  justifyContent: 'space-between',

})
const NavbarTitle = styled(Box)({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  color: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});
const webStyle = {
  joblisting: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  home: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  forwardArrow: {
    color: "#395169",
    height: "15px",
    marginTop: 4
  },
  steps: {
    color: '#475569',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  textheading: {
    color: '#64748B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px',
    marginBottom: 6
  },
  name: {
    color: '#1E293B',
    fontWeight: 700,
    fontSize: '20px',
    fontFamily: 'Inter'
  },
  align: {
    display: 'flex',
    alignItems: 'center'
  },
  amount: {
    color: '#64748B',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Inter',
  },
  lastBox: {
    display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: "15px", paddingTop: '15px'
  },
  noResultBox: {
    background: "#fff",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center" as "center",
    alignItem: "center" as "center",
    paddingTop: 88,
    paddingBottom: 128,
  },
  noResultText: {
    color: "0F172A",
    fontWeight: 600,
    marginTop: "16px",
    fontFamily: "Inter",
    fontSize: "20px",
    lineHeight: "28px",
  },
  backIcon:{
    color:'#fff'
  }
}

// Customizable Area End
