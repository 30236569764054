// Customizable Area Start
import React from "react";
import {
  ScrollView,
  SafeAreaView,
  StyleSheet,
} from "react-native";
import { Box, TextField, Typography, styled, Select, MenuItem, Button, createTheme, ThemeProvider, Checkbox, Dialog, Avatar, Divider, Popover, InputAdornment } from "@material-ui/core"

import scale, { verticalScale } from "../../../components/src/Scale";
import PostEditController, { Props ,JobWorkingDay} from "./PostEditController";
import Navbar from "../../../components/src/Navbar.web";
import Footer from "../../../components/src/Footer.web"
import { dummyProfile } from "../../../blocks/navigationmenu/src/assets";
import { ArrowBackIos, ArrowForwardIos, Close, Edit, KeyboardArrowDown } from "@material-ui/icons";
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { admin, checked, education, experience, location, logo, school, unchecked } from './assets'
import TimeInput from "../../../components/src/TimeInput.web";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import TimeDisplay from '../../../components/src/TimeDisplay.web'
import DrawerComponent from "../../../components/src/Drawer.web";
import MenuIcon from '@material-ui/icons/Menu'
import Loader from "../../../components/src/Loader.web";
export const configJSON = require("../../joblisting/src/config");
const customToolbar = [
  ['bold', 'italic'],
  [{ 'list': 'bullet' }, { 'list': 'ordered' },],
];

const modules = {
  toolbar: {
    container: customToolbar,
  },
};

export default class PostEdit extends PostEditController {
  constructor(props: Props) {
    super(props);
  }

  renderStartEndTime = (item: JobWorkingDay) => {
    return (
      <>
        {!item.full_day && (
          <>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                textAlign: "end",
              }}
            >
              <Typography style={{ ...webStyle.prefOption }}>
                Start Time:
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: "#395169",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 600,
                  lineHeight: "24px",
                }}
              >
                {item.start_time}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                textAlign: "end",
              }}
            >
              <Typography style={{ ...webStyle.prefOption }}>
                End Time:
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: "#395169",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 600,
                  lineHeight: "24px",
                }}
              >
                {item.end_time}
              </Typography>
            </Box>
          </>
        )}
      </>
    );
  };

  getEndDate (){
    return this.state.calendarValueDocStart || new Date()
  }

  renderStep1 = (currentStep: number) => {
    const enddate = this.getEndDate()
    return (
      <>
        {currentStep === 1 && (
          <>
            {!this.state.editAvailbility && (
              <>
                <Box style={webStyle.jobprefeBox}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "16px",
                      borderBottom: "1px solid #F1F5F9",
                    }}
                  >
                    <Typography style={webStyle.optionLabelText}>
                      Position Working Days
                    </Typography>
                    <Box
                      style={{
                        display: "flex",
                        gap: "13px",
                        cursor: "pointer",
                      }}
                      data-test-id="editAvailability"
                      onClick={this.editAvailability}
                    >
                      <Edit />
                      <Typography
                        style={{
                          color: "#395169",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: 400,
                          lineHeight: "24px",
                        }}
                      >
                        Edit
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      gap: "13px",
                      justifyContent: "center",
                      padding: "24px",
                    }}
                  >
                    {this.state.daysOfWeek.map(({ label, day }, index) => (
                      <Avatar
                        key={day}
                        data-test-id='avatars'
                        onClick={() =>
                          this.workingDaysClick(day, index)
                        }
                        style={{
                          ...webStyle.avtarColor,
                          cursor:"pointer",
                          background: this.state.selectedWorkingDays.includes(
                            day
                          )
                            ? "#D1FAE5"
                            : "#F1F5F9",
                          color: this.state.selectedWorkingDays.includes(day)
                            ? "#059669"
                            : "#94A3B8",
                        }}
                      >
                        {label}
                      </Avatar>
                    ))}
                  </Box>
                </Box>
                <ErrorBox style={{ marginTop: "0" }}>
                  {this.state.workingdayError}
                </ErrorBox>
              </>
            )}
            {this.state.editAvailbility && (
              <>
                <Box style={webStyle.jobprefeBox}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "16px",
                      borderBottom: "1px solid #F1F5F9",
                    }}
                  >
                    <Typography style={webStyle.optionLabelText}>
                      Position Working Days
                    </Typography>
                  </Box>
                  {this.state.workingDaysArray.map((providerType, index) => {
                    const isChecked = this.state.selectedWorkingDays.includes(
                      providerType
                    );
                    return (
                      <Box
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          borderBottom: "1px solid #F1F5F9",
                          padding: "10px 16px",
                          alignItems: "center",
                        }}
                      >
                        <Typography style={webStyle.prefOption}>
                          {providerType}
                        </Typography>
                        <ThemeProvider theme={theme}>
                          <Checkbox
                            checked={isChecked}
                            style={{ borderRadius: "10px" }}
                            data-test-id="workingDaysClicked"
                            onChange={() =>
                              this.workingDaysClick(providerType, index)
                            }
                          />
                        </ThemeProvider>
                      </Box>
                    );
                  })}
                </Box>
                <Box style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    style={webStyle.saveBtn}
                    data-test-id="saveAvailbility"
                    onClick={this.saveAvailbility}
                  >
                    Save
                  </Button>
                </Box>
              </>
            )}
            {this.state.selectedWorkingDays.length > 0 && (
              <Typography style={webStyle.preferTime}>Position Time</Typography>
            )}
            <Box>
              {this.state.workTimes.map((day) => (
                <Box
                  key={day.working_day_id}
                  style={{
                    ...webStyle.jobprefeBox,
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px 16px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography style={webStyle.prefOption}>
                      {this.state.days[day.working_day_id]}
                    </Typography>
                    <Box
                      style={{
                        display: "flex",
                        gap: "80px",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{ ...webStyle.preferTime, marginTop: "unset" }}
                      >
                        Full day
                      </Typography>
                      <div style={style2.container as React.CSSProperties}>
                        <input
                          type="checkbox"
                          data-test-id="dayToggle"
                          style={style2.checkbox as React.CSSProperties}
                          id={`checkbox${day.working_day_id}`}
                          checked={day.full_day}
                          onChange={() => this.dayToggle(day.working_day_id)}
                        />
                        <label
                          htmlFor={`checkbox${day.working_day_id}`}
                          style={{
                            ...style2.switch,
                            ...(day.full_day && style2.switchChecked),
                          }}
                        >
                          <span
                            style={
                              {
                                ...style2.slider,
                                ...(day.full_day && style2.sliderChecked),
                              } as React.CSSProperties
                            }
                          ></span>
                        </label>
                      </div>
                    </Box>
                  </Box>
                  {!day.full_day && (
                    <>
                      <TimeDisplay
                        label="Start time"
                        time={
                          this.state.time[this.state.days[day.working_day_id]]
                            ?.start
                        }
                        onClick={() =>
                          this.clickTime(this.state.days[day.working_day_id])
                        }
                        clearTime={()=>this.clearTime(this.state.days[day.working_day_id] , 'start')}
                        testId="clickTime"
                      />
                      <TimeDisplay
                        label="End time"
                        time={
                          this.state.time[this.state.days[day.working_day_id]]
                            ?.end
                        }
                        onClick={() =>
                          this.clickEndTime(this.state.days[day.working_day_id])
                        }
                        clearTime={()=>this.clearTime(this.state.days[day.working_day_id] , 'end')}
                        testId="clickTime"
                      />
                      <TimeDialog
                        open={
                          this.state.startClicked &&
                          this.state.activeDay ===
                            this.state.days[day.working_day_id]
                        }
                        data-test-id="closeTimeDialog"
                        onClose={this.closeTimeDialog}
                      >
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "24px",
                            borderBottom: "1px solid #E2E8F0",
                          }}
                        >
                          <Typography style={webStyle.popHeading}>
                            Add Start Time
                          </Typography>
                          <Close onClick={this.closeTimeDialog} />
                        </Box>
                        <Box style={{ padding: "20px" }}>
                          <TimeInput
                            data-test-id="handleTimeChange"
                            value={
                              this.state.time[
                                this.state.days[day.working_day_id]
                              ]?.start || { hour: 9, minute: 0, period: "AM" }
                            }
                            onChange={(value) =>
                              this.handleTimeChange(
                                this.state.days[day.working_day_id],
                                "start",
                                value
                              )
                            }
                          />
                          <Button
                            data-test-id="saveStartTime"
                            onClick={() => this.saveStartTime()}
                            fullWidth
                            style={{ ...webStyle.saveBtn, marginTop: "37px" }}
                          >
                            Save
                          </Button>
                        </Box>
                      </TimeDialog>
                      <TimeDialog
                        open={
                          this.state.endClicked &&
                          this.state.activeDay ===
                            this.state.days[day.working_day_id]
                        }
                        onClose={this.closeTimeDialog}
                      >
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "24px",
                            borderBottom: "1px solid #E2E8F0",
                          }}
                        >
                          <Typography style={webStyle.popHeading}>
                            Add End Time
                          </Typography>
                          <Close onClick={this.closeTimeDialog} />
                        </Box>
                        <Box style={{ padding: "20px" }}>
                          <TimeInput
                            data-test-id="handleTimeChange"
                            value={
                              this.state.time[
                                this.state.days[day.working_day_id]
                              ]?.end || { hour: 5, minute: 0, period: "PM" }
                            }
                            onChange={(value) =>
                              this.handleTimeChange(
                                this.state.days[day.working_day_id],
                                "end",
                                value
                              )
                            }
                          />
                          <Button
                            data-test-id="saveEndTime"
                            onClick={() => this.saveEndTime()}
                            fullWidth
                            style={{ ...webStyle.saveBtn, marginTop: "37px" }}
                          >
                            Save
                          </Button>
                        </Box>
                      </TimeDialog>
                    </>
                  )}
                </Box>
              ))}
            </Box>

            <Typography style={webStyle.textheading}>Start Date</Typography>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Select option"
              value={this.state.textFieldValueDocStart}
              data-test-id="handleStartCalender"
              onClick={this.handleStartCalender}
              style={{ cursor: "pointer" }}
              InputProps={{
                style: { pointerEvents: "none" },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ pointerEvents: "auto", cursor: "pointer" }}
                    onClick={this.handleStartCalender}
                  >
                    <KeyboardArrowDown />
                  </InputAdornment>
                ),
              }}
            />
            <ErrorBox style={{ marginTop: "0" }}>
              {this.state.startTimeError}
            </ErrorBox>
            <CustomPopover
              id="to-popover"
              open={Boolean(this.state.anchorElDocStart)}
              anchorEl={this.state.anchorElDocStart}
              data-test-id="handleCloseDocanchorElDocStart"
              onClose={this.handleCloseDocanchorElDocStart}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Calendar
                value={this.state.calendarValueDocStart}
                onClickDay={this.handleDateChangesdoc(
                  "calendarValueDocStart",
                  "textFieldValueDocStart",
                  "anchorElDocStart"
                )}
                data-test-id="handleDateChangesdoc"
                view="month"
                minDate={new Date()}
                maxDetail="month"
                tileContent={({ date }) => {
                  const isSelectedDate =
                    date.getDate() ===
                      this.state.calendarValueDocStart?.getDate() &&
                    date.getMonth() ===
                      this.state.calendarValueDocStart?.getMonth() &&
                    date.getFullYear() ===
                      this.state.calendarValueDocStart?.getFullYear();
                  const style = {
                    borderRadius: "10px",
                    backgroundColor: isSelectedDate ? "#395169" : undefined,
                    color: isSelectedDate ? "white" : undefined,
                  };
                  return <div style={style} />;
                }}
              />
            </CustomPopover>
            <Typography style={webStyle.textheading}>End Date</Typography>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Select option"
              value={this.state.textFieldValueDocEnd}
              data-test-id="handleendCalender"
              onClick={this.handleendCalender}
              style={{ cursor: "pointer" }}
              InputProps={{
                style: { pointerEvents: "none" },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ pointerEvents: "auto", cursor: "pointer" }}
                    onClick={this.handleendCalender}
                  >
                    <KeyboardArrowDown />
                  </InputAdornment>
                ),
              }}
            />
            <ErrorBox style={{ marginTop: "0" }}>
              {this.state.endTimeError}
            </ErrorBox>
            <CustomPopover
              id="to-popover"
              open={Boolean(this.state.anchorElDocEnd)}
              anchorEl={this.state.anchorElDocEnd}
              onClose={this.handleCloseDocanchorElDocStart}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Calendar
                value={this.state.calendarValueDocEnd}
                onClickDay={this.handleDateChangesdoc(
                  "calendarValueDocEnd",
                  "textFieldValueDocEnd",
                  "anchorElDocEnd"
                )}
                data-test-id="handleDateChangesdocTo"
                view="month"
                minDate={enddate}
                maxDetail="month"
                tileContent={({ date }) => {
                  const isSelectedDate =
                    date.getDate() ===
                      this.state.calendarValueDocEnd?.getDate() &&
                    date.getMonth() ===
                      this.state.calendarValueDocEnd?.getMonth() &&
                    date.getFullYear() ===
                      this.state.calendarValueDocEnd?.getFullYear();
                  const style = {
                    borderRadius: "10px",
                    backgroundColor: isSelectedDate ? "#395169" : undefined,
                    color: isSelectedDate ? "white" : undefined,
                  };
                  return <div style={style} />;
                }}
              />
            </CustomPopover>
            <Box style={webStyle.buttons}>
              <Button onClick={this.navigateToHome} style={webStyle.cancleBtn}>
                Cancel
              </Button>
              <Button
                data-test-id="stepOneContinue"
                onClick={this.stepOneContinue}
                style={webStyle.continueBtn}
              >
                Continue
              </Button>
            </Box>
          </>
        )}
      </>
    );
  };

  renderStep2 = (currentStep: number) => {
    return (
      <>
        {currentStep == 2 && (
          <>
            <Typography style={webStyle.textheading}>Job title</Typography>
            <InputField
              onChange={(event) => {
                this.onValueChange("jobTitle", event.target.value);
              }}
              value={this.state.jobTitle}
              data-test-id="valueChnageone"
              variant="outlined"
              fullWidth
              placeholder="Enter job title"
            />
            <ErrorBox>{this.state.jobTitleError}</ErrorBox>
            <Typography style={webStyle.textheading}>Organization</Typography>
            <InputField
              variant="outlined"
              fullWidth
              placeholder="Enter Organization"
              data-test-id="valueChnageone"
              onChange={(event) => {
                this.onValueChange("organisation", event.target.value);
              }}
              value={this.state.organisation}
            />
            <ErrorBox>{this.state.organisationError}</ErrorBox>
            <Typography style={webStyle.textheading}>
              Education level
            </Typography>
            <Select
              variant="outlined"
              fullWidth
              IconComponent={KeyboardArrowDown}
              style={{
                marginBottom: 32,
                color:
                  this.state.education == "Select option"
                    ? "#A5A5A5"
                    : "#1E293B",
              }}
              value={this.state.education}
              data-test-id="valueChnageone"
              onChange={(event) => {
                this.onValueChange("education", event.target.value);
              }}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
            >
              <MenuItem
                key="Select option"
                value="Select option"
                style={{ display: "none" }}
              >
                Select option
              </MenuItem>
              {this.state.educationList.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            <ErrorBox>{this.state.educationError}</ErrorBox>
            <Typography style={webStyle.textheading}>
              Experience required
              <span style={{ fontWeight: 400 }}> (in years)</span>
            </Typography>
            <Select
              variant="outlined"
              fullWidth
              IconComponent={KeyboardArrowDown}
              style={{
                marginBottom: 32,
                color:
                  this.state.experience === "Select option"
                    ? "#A5A5A5"
                    : "#1E293B",
              }}
              onChange={(event) =>
                this.onValueChange("experience", event.target.value)
              }
              value={this.state.experience}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
              data-test-id="valueChnageone"
            >
              <MenuItem
                key="Select option"
                value="Select option"
                style={{ display: "none" }}
              >
                Select option
              </MenuItem>
              {this.state.experienceList.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            <ErrorBox>{this.state.experienceError}</ErrorBox>
            <Typography style={webStyle.textheading}>
              Job description
            </Typography>
            <QuillWrapper>
              <ReactQuill
                theme="snow"
                style={{ height: 400 }}
                placeholder="Enter description"
                data-test-id="reactQuill"
                onChange={(html:string)=>this.handleQuilChange("jobdescription" ,html)}
                value={this.state.jobdescription}
                modules={modules}
              />
            </QuillWrapper>
            <ErrorBox style={{ marginTop: "44px" }}>
              {this.state.jobDesciptionError}
            </ErrorBox>
            <Box style={webStyle.buttons}>
              <Button onClick={this.backStepOne} style={webStyle.cancleBtn}>
                Cancel
              </Button>
              <Button
                data-test-id="stepTwoContinue"
                onClick={this.stepTwoContinue}
                style={webStyle.continueBtn}
              >
                Continue
              </Button>
            </Box>
          </>
        )}
      </>
    );
  };
  setButtonText(){
    return this.state.loader ? 'Updating': 'Update'} 
  setBg(){return this.state.loader ? '#6a8caf': '#395169'} 
  renderStep3 = (currentStep: number) => {
    const setbg = this.setBg()
    return (
      <>
        {currentStep == 3 && (
          <>
            <Box style={webStyle.checkTypo}>
              <ThemeProvider theme={theme}>
                <Checkbox
                  checked={this.state.savedAddress}
                  style={{ borderRadius: "10px" }}
                  data-test-id="savedAddressClick"
                  onChange={this.savedAddressClick}
                />
              </ThemeProvider>
              <Typography style={webStyle.saveAdd}>
                Use my saved address
              </Typography>
            </Box>

            <Typography style={webStyle.textheading}>Street address</Typography>
            <InputField
              variant="outlined"
              fullWidth
              placeholder="Enter street address"
              data-test-id="valueChnageoneTwostreet"
              onChange={(event) => {
                this.onValueChange("street", event.target.value);
              }}
              value={this.state.street}
            />
            <ErrorBox>{this.state.streetError}</ErrorBox>
            <Typography style={webStyle.textheading}>City</Typography>
            <InputField
              variant="outlined"
              fullWidth
              placeholder="Enter city"
              data-test-id="valueChnageoneTwostreet"
              onChange={(event) => {
                this.onValueChange("city", event.target.value);
              }}
              value={this.state.city}
            />
            <ErrorBox>{this.state.cityError}</ErrorBox>
            <Typography style={webStyle.textheading}>State</Typography>
            <InputField
              variant="outlined"
              fullWidth
              placeholder="Enter state"
              data-test-id="valueChnageoneTwostreet"
              onChange={(event) => {
                this.onValueChange("state", event.target.value);
              }}
              value={this.state.state}
            />
            <ErrorBox>{this.state.stateError}</ErrorBox>
            <Typography style={webStyle.textheading}>Zip code</Typography>
            <InputField
              variant="outlined"
              fullWidth
              placeholder="Enter zip code"
              data-test-id="valueChnageoneTwostreet"
              onChange={(event) => {
                this.onValueChange("zipcode", event.target.value);
              }}
              value={this.state.zipcode}
            />
            <ErrorBox>{this.state.zipcodeError}</ErrorBox>
            <Typography style={webStyle.textheading}>Country</Typography>
            <Select
              variant="outlined"
              fullWidth
              IconComponent={KeyboardArrowDown}
              style={{
                marginBottom: 32,
                color:
                  this.state.country == "Select option" ? "#A5A5A5" : "#1E293B",
              }}
              onChange={(event) => {
                this.onValueChange("country", event.target.value);
              }}
              value={this.state.country}
              data-test-id="valueChnageoneTwostreet"
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
            >
              <MenuItem
                key="Select option"
                value="Select option"
                style={{ display: "none" }}
              >
                Select option
              </MenuItem>
              {this.state.countryList.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            <ErrorBox>{this.state.countryError}</ErrorBox>
            <Typography style={webStyle.textheading}>
              About organization
            </Typography>
            <InputField
              fullWidth
              variant="outlined"
              multiline
              minRows={10}
              placeholder="Write something..."
              onChange={(event) => {
                this.onValueChange("aboutOrg", event.target.value);
              }}
              value={this.state.aboutOrg}
              data-test-id="valueChnageoneTwostreet"
            />
            <ErrorBox style={{ marginTop: "3px" }}>
              {this.state.aboutOrgError}
            </ErrorBox>
            <Box style={webStyle.buttons}>
              <Button
                data-test-id="handleCancleTwo"
                onClick={this.handleCancleTwo}
                style={webStyle.cancleBtn}
              >
                Cancel
              </Button>
              <Box>
                <Button
                  data-test-id="handlePreview"
                  onClick={this.handlePreview}
                  style={webStyle.preview}
                >
                  Preview
                </Button>
                <Button
                  data-test-id="publishBtn"
                  onClick={this.publishBtn}
                  style={{...webStyle.continueBtn , background:setbg}}
                  disabled={this.state.loader}
                >
                  {this.setButtonText()}
                </Button>
              </Box>
            </Box>

            <CustomDialog
              open={this.state.openPreview}
              onClose={this.closePreview}
              data-test-id="closePreview"
            >
              <Box style={{ height: "708px" }}>
                <Box style={webStyle.header}>
                  <Typography style={webStyle.headerHeading}>
                    Job post preview
                  </Typography>
                  <Close onClick={this.closePreview} />
                </Box>
                <Box style={webStyle.mainBox}>
                  <JobDetailsTopBox style={{marginTop:"0px"}}>
                    <Box style={webStyle.jobTitleMain}>
                      <Box>
                        <MainTitle>{this.state.jobTitle}</MainTitle>
                        <MainCompanyName>
                          {this.state.organisation}
                        </MainCompanyName>
                      </Box>
                      <DisplayForDesktop>
                        <ImageAvatar alt="company_logo" src={school} />
                      </DisplayForDesktop>
                    </Box>
                    <JobTitleTopMiddleSection>
                      <JobTitleTopMiddleSmallBox>
                        <Box style={webStyle.jobTitleMiddleInnerBox}>
                          <img src={experience} alt="" />
                          <JobTitleTopCategoryTypo>
                            EXPERIENCE
                          </JobTitleTopCategoryTypo>
                        </Box>
                        <JobTitleTopCategoryDetail>
                          {this.state.experience}
                        </JobTitleTopCategoryDetail>
                      </JobTitleTopMiddleSmallBox>
                      <JobTitleTopMiddleSmallBox>
                        <Box style={webStyle.jobTitleMiddleInnerBox}>
                          <img src={education} alt="" />
                          <JobTitleTopCategoryTypo>
                            EDUCATION LEVEL
                          </JobTitleTopCategoryTypo>
                        </Box>
                        <JobTitleTopCategoryDetail>
                          {this.state.education}
                        </JobTitleTopCategoryDetail>
                      </JobTitleTopMiddleSmallBox>
                      <JobTitleTopMiddleSmallBox>
                        <Box style={webStyle.jobTitleMiddleInnerBox}>
                          <img src={location} alt="" />
                          <JobTitleTopCategoryTypo>
                            LOCATION
                          </JobTitleTopCategoryTypo>
                        </Box>
                        <JobTitleTopCategoryDetail>
                          {this.state.city}, {this.state.state},{" "}
                          {this.state.country}
                        </JobTitleTopCategoryDetail>
                      </JobTitleTopMiddleSmallBox>
                    </JobTitleTopMiddleSection>
                    <JobDetailsBottomDivider />
                    <Box style={webStyle.postedAtBox}>
                      Posted:
                      <Typography style={webStyle.postedAtText}>
                        Just now
                      </Typography>
                    </Box>
                  </JobDetailsTopBox>

                  <PostedByBox>
                    <PostedByTextTypography>Posted by</PostedByTextTypography>
                    <PostedByDetailsBox>
                      <PostedByDetailsLeftBox>
                        <ImageAvatar src={this.state.profileImage || dummyProfile} alt="" />
                        <PostedByDetailsMiddleBox>
                          <PostedByNameTypography>
                            {this.state.firstname} {this.state.lastname}
                          </PostedByNameTypography>
                          <PostedByNameDetailsBox>
                            <AdminTypeTypography>
                              School Admin
                            </AdminTypeTypography>
                            <SchoolNameDot>.</SchoolNameDot>
                            <SchoolNameTypography>
                              {this.state.organisation}
                            </SchoolNameTypography>
                          </PostedByNameDetailsBox>
                        </PostedByDetailsMiddleBox>
                      </PostedByDetailsLeftBox>
                    </PostedByDetailsBox>
                    <DividerForMobile />
                  </PostedByBox>
                  <PostedByBox>
                    <JobDescTitle>{configJSON.positionTitle}</JobDescTitle>
                    {this.state.job_working_times.map((item, index) => (
                      <Box key={index} style={{ marginTop: "10px" }}>
                        <Box
                          style={{textAlign: "end",justifyContent: "space-between", display: "flex",alignItems: "center", width: "100%",}}>
                          <Typography style={{ ...webStyle.preTime }}>{this.state.days[item.working_day_id]}</Typography>
                          <Typography
                            style={{fontSize: "14px",fontFamily: "Inter",color: "#395169", lineHeight: "24px",fontWeight: 600,}}
                          >{item.full_day ? "Full Day" : ""}</Typography>
                        </Box>
                        <>
                        {this.renderStartEndTime(item)}</>
                        <Box>
                        </Box>
                      </Box>
                    ))}
                  </PostedByBox>
                  <PostedByBox>
                    <Box
                      style={{display: "flex",justifyContent: "space-between",alignItems: "center",width: "100%", textAlign: "end" }}>
                      <Typography style={{ ...webStyle.preTime }}>
                        Start Date
                      </Typography>
                      <Typography
                        style={{color: "#395169",fontFamily: "Inter",fontSize: "14px", fontWeight: 600, lineHeight: "24px",}}>
                        {this.state.textFieldValueDocStart}
                      </Typography>
                    </Box>

                    <Box
                      style={{display: "flex",justifyContent: "space-between",alignItems: "center",width: "100%",textAlign: "end"}}>
                      <Typography style={{ ...webStyle.preTime }}>
                        End Date
                      </Typography>
                      <Typography
                        style={{
                          color: "#395169",fontFamily: "Inter",fontSize: "14px",fontWeight: 600,lineHeight: "24px",}}>
                        {this.state.textFieldValueDocEnd}
                      </Typography>
                    </Box>
                  </PostedByBox>
                  <JobDescriptionMainBox>
                    <JobDescTitle>Job Description</JobDescTitle>
                    <JobDescJobTitleBox>
                      Job Title:
                      <JobDescJobTitle>{this.state.jobTitle}</JobDescJobTitle>
                    </JobDescJobTitleBox>
                    <AboutOrg>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.jobdescription,
                        }}
                      />
                    </AboutOrg>
                    <DividerForMobile />
                  </JobDescriptionMainBox>

                  <AboutOrganizationMainBox>
                    <AboutOrganizationTitle>
                      About Organization
                    </AboutOrganizationTitle>
                    <Box style={webStyle.aboutOrganizationTop}>
                      <ImageAvatar src={school} alt="" />
                      <AboutOrgDetailBox>
                        <AboutOrgName>{this.state.organisation}</AboutOrgName>
                        <AboutOrgLocation>
                          {this.state.city}, {this.state.state},{" "}
                          {this.state.country}
                        </AboutOrgLocation>
                      </AboutOrgDetailBox>
                    </Box>
                    <AboutOrg>{this.state.aboutOrg}</AboutOrg>
                  </AboutOrganizationMainBox>
                </Box>
              </Box>
            </CustomDialog>
          </>
        )}
      </>
    );
  };

  getLoaderScreen (){
    return  <div style={{height:"50vh" , width:"100%" , display:"flex" , justifyContent:"center" , alignItems:"center"}}>
    <Loader loading={this.state.loader}/>
  </div>
  }

  getFinalRender(){
    return !this.state.loader ? this.renderStep3(this.state.currentStep) : this.getLoaderScreen()
  }
  render() {
    return (
      <SafeAreaView style={styles.safeAreaView}>
        <ScrollView>
          <CustomNavbar>
            <Navbar
              navigation={this.props.navigation}
              id={""}
              NaviagationPage={""}
            />
          </CustomNavbar>
          <CustomProfileHead>
            <ArrowBackIos
              data-test-id="btnBack"
              onClick={this.navigateToHome}
              style={webStyle.backIcon}
            />
            <NavbarTitle>
              <img src={logo} />
            </NavbarTitle>
            <MenuIcon
          style={{ color: '#fff', position: 'absolute', right: '47px',cursor:'pointer' }}
          onClick={() => this.toggleDrawer(true)}
          data-test-id="toggleDrawer"
        />
          <DrawerComponent
          isOpen={this.state.isDrawerOpen}
          toggleDrawer={this.toggleDrawer} 
          navigation={this.props.navigation}        
          />
          </CustomProfileHead>
          <MainBox>
            <Navigation>
              <Typography
                data-test-id="navigateToHome"
                onClick={this.navigateToHome}
                style={{ ...webStyle.home, cursor: "pointer" }}
              >
                Home
              </Typography>
              <ArrowForwardIos style={webStyle.forwardArrow} />
              <Typography
                style={{ ...webStyle.joblisting, cursor: "pointer" }}
                data-test-id="navigateToHome"
                onClick={() => this.handleBack("JobCreated")}
              >
                Jobs Created
              </Typography>
              <ArrowForwardIos style={webStyle.forwardArrow} />
              <Typography
                style={{ ...webStyle.joblisting, cursor: "pointer" }}
                data-test-id="navigateToHome"
                onClick={() => this.handleBack("JobDetails")}
              >
                Job Details
              </Typography>
              <ArrowForwardIos style={webStyle.forwardArrow} />
              <Typography style={webStyle.joblisting}>Edit job post</Typography>
            </Navigation>
            <ProfileHeading>
              <PostHeading>Edit job post</PostHeading>
              <Typography style={webStyle.steps}>
                Step {this.state.currentStep} / 3
              </Typography>
            </ProfileHeading>
            {this.renderStep1(this.state.currentStep)}
            {this.renderStep2(this.state.currentStep)}
            {this.getFinalRender()}
          </MainBox>
          <Footer
            navigation={this.props.navigation}
            id={""}
            NaviagationPage={""}
          />
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const theme = createTheme({
  props: {
    MuiCheckbox: {
      icon: <img src={unchecked} />,
      checkedIcon: <img src={checked} />,
    },
  },
});
const CustomPopover = styled(Popover)({
  "& .MuiPaper-rounded": {
    padding: 10,
    borderRadius: "16px",
  },
  width: "100%",
  "& .react-calendar": {border: "unset"},
  "& .react-calendar__tile--active:enabled": {
    borderRadius: "10px",
    background: "#395169",
  },
  "& .react-calendar__tile:enabled:hover": {
    borderRadius: "10px"},
  "& .react-calendar__year-view .react-calendar__tile": {
    padding: "1rem 0.5rem"},
  "& .react-calendar button": {
    borderRadius: "10px",
    border: "1px solid #E2E8F0",
  },
  "& .react-calendar__navigation button": {
    border: "none"},
});
const TimeDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    height: "256px",
    width: 375,
    borderRadius: "16px",
  },
});
const CustomDialog = styled(Dialog)({
  borderRadius: "16px",
  "& .MuiDialog-paperWidthSm": {
    borderRadius: "16px",
    minWidth: "700px",
    "@media only screen and (max-width: 816px)": {
      minWidth: "500px"},
    "@media only screen and (max-width: 553px)": {
      minWidth: "300px"},
  },
});
const PostHeading = styled(Typography)({
  fontSize: "30px",
  fontFamily: "Inter",
  color: "#1E293B",
  lineHeight: "40px",
  fontWeight: 700,
  "@media only screen and (max-width: 414px)": {
    fontSize: "16px"},
});
const ErrorBox = styled(Box)({
  height: "18px",
  color: "#DC2626",
  fontSize: "12px",
  fontFamily: "Inter",
  lineHeight: "18px",
  fontWeight: 400,
  marginBottom: "10px",
  marginTop: "-33px",
  "@media only screen and (max-width: 414px)": {
    marginTop: "-33px"},
});
const NavbarTitle = styled(Box)({
  fontWeight: 600,
  fontFamily: "Inter",
  lineHeight: "24px",
  fontSize: "16px",
  transform: "translate(-50%, -50%)",
  position: "absolute",
  color: "#FFFFFF",
  left: "50%",
  top: "50%",
});
const role = localStorage.getItem("role");
const width = role === '1' ? 850 : 657; 

const CustomProfileHead = styled(Box)({
  display: 'none',
  [`@media only screen and (max-width: ${width}px)`]: {
    backgroundColor: '#395169',
    display: 'flex',
    padding: 26,
    position: 'relative',
    paddingBottom: 37, 
    alignItems: 'center'
  },
});
const CustomNavbar = styled(Box)({
  [`@media only screen and (max-width: ${width}px)`]: {
    display: 'none', 
  },
});
const QuillWrapper = styled(Box)({
  display: "flex",
  borderTop: "1px solid #ccc",
  position: "relative",
  flexDirection: "column-reverse",
  borderRadius: 8,
  "& .ql-toolbar": {
    bottom: 0,
    width: "100%",
    borderBottomRightRadius: "8px",
    transform: "translateY(100%)",
    position: "absolute",
    borderBottomLeftRadius: "8px",
    borderTop: "none",
  },
  "& .ql-container": {
    borderTopRightRadius: "8px",
    borderTopLeftRadius: "8px",
  },
  "& .ql-editor::before": {
    fontFamily: "Inter",
    color: "#A5A5A5",
    fontStyle: "normal",
    fontSize: "16px",
  },
});
const MainBox = styled(Box)({
  position: "relative",
  background: "#F8F8F8",
  padding: "140px 426px 120px 426px",
  "@media only screen and (max-width: 1330px)": {
    padding: 100,
  },
  "@media only screen and (max-width: 688px)": {
    paddingRight: 29,
    paddingLeft: 29,
  },
  "@media only screen and (max-width: 571px)": {
    padding: "29px",
  },
  "@media only screen and (max-width: 404px)": {
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: "29px",
    paddingBottom: "29px",
  },
  "@media only screen and (max-width: 375px)": {
    paddingRight: 4,
    paddingTop: "29px",
    paddingLeft: 4,
    paddingBottom: "29px",
  },
});
const Navigation = styled(Box)({
  position: "absolute" as "absolute",
  gap: "10px",
  display: "flex",
  left: 114,
  top: 32,
  "@media only screen and (max-width: 657px)": {
    left: 38},
  "@media only screen and (max-width: 571px)": {
    display: "none"},
});
const ProfileHeading = styled(Box)({
  justifyContent: "space-between",
  display: "flex",
  marginBottom: "32px",
});
const webStyle = {
  joblisting: {
    lineHeight: "22px",
    fontFamily: "Inter",
    color: "#1E293B",
    fontWeight: 400,
    fontSize: "14px",
  },
  home: {
    fontFamily: "Inter",
    lineHeight: "22px",
    color: "#475569",
    fontWeight: 400,
    fontSize: "14px",
  },
  forwardArrow: {
    marginTop: 4,
    height: "15px",
    color: "#395169",
  },
  steps: {
    fontFamily: "Inter",
    color: "#475569",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  textheading: {
    fontFamily: "Inter",
    color: "#64748B",
    fontWeight: 700,
    fontSize: "14px",
    marginBottom: 6,
    lineHeight: "22px",
    marginTop: "27px",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "130px",
  },
  cancleBtn: {
    fontFamily: "Inter",
    color: "#395169",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
    textTransform: "none" as "none",
  },
  continueBtn: {
    background: "#395169",
    fontFamily: "Inter",
    color: "#fff",
    fontWeight: 700,
    padding: "16px",
    borderRadius: "8px",
    fontSize: "16px",
    textTransform: "none" as "none",
    lineHeight: "24px",
  },
  preview: {
    background: "#fff",
    borderRadius: "8px",
    padding: "16px",
    border: "1px solid #395169",
    fontFamily: "Inter",
    color: "#0F172A",
    textTransform: "none" as "none",
    marginRight: "16px",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
  },
  checkTypo: {
    gap: 2,
    display: "flex",
    marginLeft: "-7px",
    marginBottom: 32,
  },
  saveAdd: {
    fontSize: "16px",
    color: "#0F172A",
    fontFamily: "Inter",
    marginTop: "6px",
    lineHeight: "24px",
    fontWeight: 400,
  },
  jobTitleMain: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  jobTitleMiddleInnerBox: {
    alignItems: "center",
    display: "flex",
  },
  postedAtBox: {
    fontFamily: "Inter",
    color: "#64748B",
    lineHeight: "18px",
    fontWeight: 400,
    fontSize: "12px",
  },
  postedAtText: {
    fontSize: "12px",
    fontWeight: 600,
    display: "inline-block",
    lineHeight: "18px",
    fontFamily: "Inter",
    color: "#4788b7",
    marginLeft: "4px"},
  aboutOrganizationTop: {
    display: "flex"},
  headerHeading: {
    fontFamily: "Inter",
    lineHeight: "32px",
    color: "#0F172A",
    fontWeight: 700,
    fontSize: "24px",
  },
  header: {
    background: "#fff",
    padding: "24px 16px",
    justifyContent: "space-between",
    display: "flex",
  },
  mainBox: {
    background: "#F8F8F8",
    padding: "24px",
  },
  schoolname: {
    fontFamily: "Inter",
    fontSize: "16px",
    color: "#475569",
    lineHeight: "24px",
    fontWeight: 400,
  },
  buttonbox: {
    justifyContent: "space-between",
    padding: "24px 40px",
    display: "flex",
  },
  cancelBtn: {
    color: "#395169",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    width: "100%",
    lineHeight: "24px",
    textTransform: "none" as "none",
    background: "#fff",
    height: "56px",
    cursor: "pointer",
  },
  submitBtn: {
    color: "#fff",
    width: "100%",
    background: "#395169",
    fontSize: "16px",
    fontFamily: "Inter",
    lineHeight: "24px",
    fontWeight: 700,
    cursor: "pointer",
    height: "56px",
    textTransform: "none" as "none",
  },
  deleteBox: {
    position: "relative" as "relative",
    padding: "60px 40px 32px 40px",
  },
  deleteClose: {
    right: 20,
    position: "absolute" as "absolute",
    cursor: "pointer",
    top: 20,
  },
  deleteConfirm: {
    borderTop: "1px solid #E2E8F0",
    display: "flex",
    padding: "20px 40px",
    justifyContent: "flex-end",
    gap: "16px",
  },
  jobprefeBox: {
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
    marginTop: "16px",
  },
  preferTitle: {
    fontFamily: "Inter",
    color: "#1E293B",
    fontWeight: 700,
    fontSize: "16px",
    borderBottom: "#F1F5F9",
    padding: "15px 16px",
    lineHeight: "24px",
  },
  prefOption: {
    fontFamily: "Inter",
    color: "#1E293B",
    lineHeight: "22px",
    fontWeight: 400,
    fontSize: "14px",
  },
  preTime: {
    fontFamily: "Inter",
    color: "#1E293B",
    fontWeight: 600,
    lineHeight: "22px",
    fontSize: "14px",
  },
  travel: {
    fontFamily: "Inter",
    color: "#475569",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 400,
    marginBottom: "10px",
    marginTop: "20px",
  },
  avtarColor: {
    height: "32px",
    width: "32px",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 700,
  },
  preferTime: {
    fontFamily: "Inter",
    color: "#395169",
    fontWeight: 400,
    fontSize: "14px",
    marginTop: "16px",
    lineHeight: "22px",
  },
  optionLabelText: {
    fontSize: "16px",
    fontFamily: "Inter",
    lineHeight: "24px",
    color: "#1E293B",
    fontWeight: 700,
  },
  saveBtn: {
    background: "#395169",
    color: "#FFFFFF",
    fontSize: "16px",
    fontFamily: "Inter",
    lineHeight: "24px",
    fontWeight: 700,
    cursor: "pointer",
    textTransform: "none" as "none",
    marginTop: 16,
    borderRadius: 8,
    padding: "10px 16px",
  },
  editText: {
    marginLeft: 5,
    color: "#1E293B",
    fontSize: "14px",
    fontFamily: "Inter",
    cursor: "pointer",
    lineHeight: "22px",
    fontWeight: 400,
  },
  popHeading: {
    color: "#1E293B",
    lineHeight: "26px",
    fontFamily: "Inter" as "Inter",
    fontSize: "18px",
    fontWeight: 700,
  },
  backIcon: {
    color: "#fff",
  },
};
const styles = StyleSheet.create({
  safeAreaView: {},
  inputTextField: {
    borderColor: "grey",
    borderWidth: 1,
    height: verticalScale(80),
    fontSize: scale(16.7),
    color: "black",
    margin: scale(3),
    borderBottomWidth: 0.3,
    marginTop: verticalScale(5),
    padding: 10,
  },

  inputTextField1: {
    borderColor: "grey",
    height: verticalScale(50),
    color: "black",
    borderWidth: 1,
    borderBottomWidth: 0.3,
    fontSize: scale(16.7),
    paddingRight: 10,
    margin: scale(3),
    paddingLeft: 10,
  },

  buttonStyle: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#366ef9",
    borderRadius: 22,
    marginTop: verticalScale(20),
    height: scale(50),
  },
  imageStyle: {
    height: scale(100),
    width: scale(100),
    borderWidth: 1,
    borderRadius: scale(50),
    borderColor: "black",
    marginBottom: verticalScale(25),
    opacity: 0.6,
    marginTop: verticalScale(5),
  },
  buttonText: {
    color: "white" },
  editIcon: {
    height: scale(20),
    position: "absolute",
    resizeMode: "contain",
    top: verticalScale(90),
    width: scale(20),
    left: scale(90),
  },
});
const style2 = {
  container: {
    width: "51px",
    position: "relative",
    height: "31px",
  },
  checkbox: {
    width: 0,
    opacity: 0,
    position: "absolute",
    cursor: "pointer",
    height: 0,
  },
  checkRecom: {
    width: 0,
    opacity: 0,
    position: "absolute",
    height: 0,
  },
  switch: {
    width: "100%",
    display: "block",
    height: "100%",
    borderRadius: "16px",
    backgroundColor: "#E2E8F0",
    transition: "all 0.2s ease-out",
    cursor: "pointer",
  },
  slider: {
    height: "27px",
    cursor: "pointer",
    width: "27px",
    left: "calc(50% - 27px/2 - 10px)",
    position: "absolute",
    borderRadius: "50%",
    top: "calc(50% - 27px/2)",
    boxShadow:"0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06)",
    background: "#FFFFFF",
    transition: "all 0.2s ease-out",
  },
  switchChecked: {
    backgroundColor: "#395169" },
  sliderChecked: {
    left: "calc(50% - 27px/2 + 10px)"},
};

const JobDetailsTopBox = styled(Box)({
  'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
  backgroundColor: "#FFFFFF",
  margin: 'auto',
  marginTop: '32px',
  padding: '32px 32px 32px 32px',
  borderRadius: '16px',
  "@media only screen and (max-width: 816px)": {padding: '24px 24px 24px 24px'},
  "@media only screen and (max-width: 620px)": {padding: '16px 16px 16px 16px'},
  "@media only screen and (max-width: 414px)": {'box-shadow': 'none',borderRadius: '0',paddingTop: '0px',},
});

const MainTitle = styled(Typography)({
  color: '#0F172A',
  fontFamily: 'Inter',
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: 600,
  "@media only screen and (max-width: 816px)": {fontSize: '20px',lineHeight: '30px'},
  "@media only screen and (max-width: 620px)": { fontSize: '18px', lineHeight: '28px',},
});

const MainCompanyName = styled(Typography)({
  color: '#475569',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  marginTop: "8px",
  "@media only screen and (max-width: 816px)": {fontSize: '14px',lineHeight: '22px'},
  "@media only screen and (max-width: 620px)": {fontSize: '14px',lineHeight: '20px',marginTop: "4"},
  "@media only screen and (max-width: 414px)": {display: 'none'},
});

const JobTitleTopMiddleSection = styled(Box)({
  display: 'flex',
  alignItems: 'start',
  marginTop: '32px',
  "@media only screen and (max-width: 816px)": {
    display: 'block',
  },
  "@media only screen and (max-width: 620px)": {
    display: 'block',
  },
  "@media only screen and (max-width: 414px)": {
    alignItems: 'center',
    display: 'flex',
    margin: 'auto',
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    position: 'relative', marginTop: '16px'},
});

const JobTitleTopMiddleSmallBox = styled(Box)({
  marginRight: '48px',
  "@media only screen and (max-width: 816px)": {
    alignItems: 'center',
    marginBottom: '8px',
    display: 'flex',
  },
  "@media only screen and (max-width: 620px)": {
    width: '100%',
    marginBottom: '8px'},
  "@media only screen and (max-width: 414px)": {marginBottom: '8px',margin: 'auto' },
});

const JobTitleTopCategoryTypo = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '18px',
  color: '#64748B',
  marginLeft: '8px',
  "@media only screen and (max-width: 816px)": {
    width: '120px',
  },
  "@media only screen and (max-width: 414px)": {display: 'none'},
});

const JobTitleTopCategoryDetail = styled(Typography)({
  marginTop: '8px',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  color: '#334155',
  width: '100%',
  "@media only screen and (max-width: 816px)": {
    margin: '0 0 0 8px',
  },
  "@media only screen and (max-width: 620px)": {margin: '0 0 0 8px',fontSize: '14px'},
});

const JobDetailsBottomDivider = styled(Divider)({
  margin: '32px 0 32px 0',
  "@media only screen and (max-width: 414px)": {margin: '16px 0 16px 0'},
});

const PostedByBox = styled(Box)({
  'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
  backgroundColor: "#FFFFFF",
  marginTop: '24px',
  margin: 'auto',
  borderRadius: '16px',
  padding: '32px 32px 32px 32px',
  "@media only screen and (max-width: 816px)": {padding: '24px 24px 24px 24px'},
  "@media only screen and (max-width: 620px)": {padding: '16px 16px 16px 16px'},
  "@media only screen and (max-width: 414px)": {
    'box-shadow': 'none',
    paddingTop: '0',
    borderRadius: '0',
    paddingBottom: '0',
    marginTop: '0px',
  },
});

const PostedByTextTypography = styled(Typography)({
  marginBottom: '24px',
  fontSize: '20px',
  fontFamily: 'Inter',
  fontWeight: 600,
  color: '#1E293B',
  "@media only screen and (max-width: 816px)": {fontSize: '18px'},
  "@media only screen and (max-width: 620px)": {fontSize: '16px'},
  "@media only screen and (max-width: 414px)": {display: 'none'},
});

const PostedByDetailsBox = styled(Box)({
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
  "@media only screen and (max-width: 816px)": {display: 'block'},
  "@media only screen and (max-width: 620px)": {display: 'block'},
});

const PostedByDetailsLeftBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
});

const ImageAvatar = styled(Avatar)({
  height: '56px',
  width: '56px',
});

const PostedByDetailsMiddleBox = styled(Box)({
  marginLeft: '16px',
});

const PostedByNameTypography = styled(Typography)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '18px',
  fontFamily: 'Inter',
  lineHeight: '26px',
  color: '#1E293B',
  fontWeight: 500,
  "@media only screen and (max-width: 414px)": {fontSize: '14px'},
});

const PostedByNameDetailsBox = styled(Box)({
  display: 'flex',
  marginTop: '4px',
  "@media only screen and (max-width: 620px)": {
    display: 'block',
  },
  "@media only screen and (max-width: 414px)": {display: 'flex',alignItems: "center"},
});

const SchoolNameDot = styled(Box)({
  color: '#64748B',
  marginLeft: '12px',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  "@media only screen and (max-width: 620px)": {display: 'none'},
  "@media only screen and (max-width: 414px)": {
    marginLeft: '6px',
    display: 'block',
    fontSize: '12px',
  },
})

const AdminTypeTypography = styled(Typography)({
  fontWeight: 400,
  fontSize: '14px',
  fontFamily: 'Inter',
  color: '#64748B',
  lineHeight: '22px',
  "@media only screen and (max-width: 414px)": {fontSize: '12px'}
});

const SchoolNameTypography = styled(Typography)({
  color: '#64748B',
  marginLeft: '12px',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  "@media only screen and (max-width: 620px)": {margin: '4px 0 0 0'},
  "@media only screen and (max-width: 414px)": {fontSize: '12px',margin: '0 0 0 6px'},
});
const JobDescriptionMainBox = styled(Box)({
  'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
  marginTop: '24px',
  borderRadius: '16px',
  backgroundColor: "#FFFFFF",
  padding: '32px 32px 32px 32px',
  margin: 'auto',
  "@media only screen and (max-width: 816px)": {padding: '24px 24px 24px 24px'},
  "@media only screen and (max-width: 620px)": {padding: '16px 16px 16px 16px'},
  "@media only screen and (max-width: 414px)": {
    paddingTop: '0','box-shadow': 'none', borderRadius: '0'},
});

const JobDescTitle = styled(Typography)({
  fontSize: '20px',
  fontFamily: 'Inter',
  fontWeight: 600,
  color: '#1E293B',
  lineHeight: '28px',
  "@media only screen and (max-width: 816px)": {
    lineHeight: '26px',
    fontSize: '18px',
  },
  "@media only screen and (max-width: 620px)": {
    lineHeight: '26px',
    fontSize: '16px',
  },
});

const JobDescJobTitleBox = styled(Box)({
  color: '#334155',
  fontSize: '16px',
  fontFamily: 'Inter',
  lineHeight: '25.6px',
  fontWeight: 600,
  "@media only screen and (max-width: 620px)": {fontSize: '14px',lineHeight: '22px'},
});

const JobDescJobTitle = styled(Typography)({
  fontSize: '16px',
  display: 'inline-block',
  lineHeight: '25.6px',
  fontFamily: 'Inter',
  color: '#334155',
  fontWeight: 400,
  marginTop: '24px',
  marginLeft: '4px',
  "@media only screen and (max-width: 620px)": {fontSize: '14px',lineHeight: '22px',marginTop: '12px'},
});

const AboutOrganizationMainBox = styled(Box)({
  'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
  backgroundColor: "#FFFFFF",
  marginTop: '24px',
  margin: 'auto',
  borderRadius: '16px',
  padding: '32px 32px 32px 32px',
  "@media only screen and (max-width: 816px)": {padding: '24px 24px 24px 24px'},
  "@media only screen and (max-width: 620px)": {padding: '16px 16px 16px 16px'},
  "@media only screen and (max-width: 414px)": {
    borderRadius: '0',
    'box-shadow': 'none',
    paddingTop: "0px",
    marginTop: '0px',
  },
});

const AboutOrganizationTitle = styled(Typography)({
  lineHeight: '28px',
  fontWeight: 600,
  color: '#1E293B',
  fontSize: '20px',
  fontFamily: 'Inter',
  marginBottom: '24px',
  "@media only screen and (max-width: 620px)": {
    marginBottom: '20px',
    fontSize: '18px',
    lineHeight: '24px'},
  "@media only screen and (max-width: 414px)": {display: 'none',},
});

const AboutOrgDetailBox = styled(Box)({marginLeft: '16px'});

const AboutOrgName = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '26px',
  "@media only screen and (max-width: 620px)": {
    fontSize: '16px',
  },
});

const AboutOrgLocation = styled(Typography)({
  color: '#64748B',
  marginTop: '4px',
  fontWeight: 400,
  fontSize: '14px',
  fontFamily: 'Inter',
  lineHeight: '22px',
});

const AboutOrg = styled(Typography)({
  color: '#334155',
  fontSize: '16px',
  fontFamily: 'Inter',
  lineHeight: '25.6px',
  marginTop: '24px',
  fontWeight: 400,
  "@media only screen and (max-width: 620px)": {
    marginTop: '20px',
    fontSize: '14px',
  },
});

const DividerForMobile = styled(Divider)({
  "@media only screen and (max-width: 414px)": {
    margin: '16px 0 16px 0',
    display: 'block',
  },
  display: 'none',
});

const DisplayForDesktop = styled(Box)({
  "@media only screen and (max-width: 414px)": {display: 'none'},
});
export const InputField = styled(TextField)({
  marginBottom: "32px",
  "& .MuiOutlinedInput-input": {
    "&::placeholder": {
      color: '#9A3B8',
      fontWeight: 500
    },
  },
});
// Customizable Area End